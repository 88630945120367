/**
 * Js handle add to cart
 *
 * @version 1.0.2
 * @since 3.0.0
 */

import {} from './add-item-to-cart.js';
import './check-course-product.js';
//import {} from './add-package-to-cart.js'

