const checkRemoveAddBtn = () => {
	const productEls = Array.prototype.slice.call( document.querySelectorAll( '.wc-block-product' ) );
	const singleProductBtn = document.querySelector( '.wc-block-add-to-cart-form' );
	const singleProductClassic = document.querySelector( '.summary.entry-summary' );
	const productListEls = document.querySelector( '.products' );

	if ( singleProductClassic ) {
		const outStockEl = singleProductClassic.querySelector( '.out-of-stock' );
		const addToCart = singleProductClassic.querySelector( 'form.cart' );
		if ( outStockEl && addToCart ) {
			addToCart.remove();
		}
	}

	if ( singleProductBtn ) {
		const outStockEl = document.querySelector( '.out-of-stock' );
		if ( outStockEl ) {
			singleProductBtn.remove();
		}
	}

	if ( productEls && productEls.length > 0 ) {
		productEls.map( ( productEl ) => {
			const outStockEl = productEl.querySelector( '.out-of-stock' );
			if ( outStockEl ) {
				const btnProduct = productEl.querySelector( '.wc-block-components-product-button' );
				if ( btnProduct ) {
					btnProduct.remove();
				}
			}
		} );
	}

	if ( productListEls ) {
		const productEls = Array.prototype.slice.call( productListEls.querySelectorAll( '.product' ) );
		if ( productEls && productEls.length > 0 ) {
			productEls.map( ( productEl ) => {
				const addToCartBtnEl = productEl.querySelector( '.add_to_cart_button' );
				const outStockEl = productEl.querySelector( '.out-of-stock' );
				if ( outStockEl && addToCartBtnEl ) {
					addToCartBtnEl.remove();
				}
			} );
		}
	}
};

const accordionCourse = () => {
	const listCourseEls = Array.prototype.slice.call( document.querySelectorAll( '.lp-course-sold-out' ) );
	if ( ! listCourseEls || listCourseEls.length < 1 ) {
		return;
	}

	listCourseEls.map( ( listCourseEl ) => {
		const titleCourse = listCourseEl.querySelector( '.lp-course-sold-out__title' );
		const contentCourse = listCourseEl.querySelector( '.lp-course-sold-out__list' );
		if ( ! titleCourse || ! contentCourse ) {
			return;
		}

		titleCourse.addEventListener( 'click', () => {
			contentCourse.classList.toggle( 'is-active' );
		} );
	} );
};

document.addEventListener( 'DOMContentLoaded', () => {
	checkRemoveAddBtn();
	accordionCourse();
} );
