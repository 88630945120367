// import Modal from 'modal-vanilla';
import Swal from 'sweetalert2';
/**
 * Add course to cart
 *
 * @since 4.1.4
 * @version 1.0.0
 */
let isAddingToCart = [];

// Event click add item to cart
document.addEventListener( 'submit', function( e ) {
	const target = e.target;
	if ( target.name === 'form-add-item-to-cart' ) {
		addItemToCart( e, target );
	}
} );

/**
 * Add item to cart
 *
 * @param e
 * @param form
 */
const addItemToCart = function( e, form ) {
	e.preventDefault();

	/**
	 * For theme Eduma
	 * When user not login, click add-to-cart will show popup login
	 * Set params submit course
	 */
	const elThimLoginPopup = document.querySelector( '.thim-login-popup .login' );
	if ( elThimLoginPopup && 'yes' !== lpWoo.woo_enable_signup_and_login_from_checkout &&
		'yes' !== lpWoo.woocommerce_enable_guest_checkout ) {
		if ( parseInt( lpData.user_id ) === 0 ) {
			elThimLoginPopup.click();
			return;
		}
	}

	let elItemId = form.querySelector('input[name="item-id"]');
	if ( ! elItemId ) {
		return;
	}

	const itemID = elItemId.value;
	const indexOf = isAddingToCart.indexOf( itemID );
	if ( isAddingToCart.indexOf( itemID ) > -1 ) {
		return;
	}
	isAddingToCart.push( itemID );

	const lang = lpData.urlParams.lang ? `?lang=${ lpData.urlParams.lang }` : '';
	const btnSubmit = form.querySelector( 'button[type="submit"]' );
	let formData = new FormData( form );
	formData.append( 'action', `lpWooAddItemToCart` );
	formData.append( 'nonce', lpWoo.nonce );
	btnSubmit.innerText = lpWoo.adding_i18n;

	fetch( lpWoo.url_ajax + lang, {
		method: 'POST',
		body: formData,
	} )
		.then( ( res ) => res.text() )
		.then( ( res ) => {
			const dataObj = JSON.parse( res );
			const { data, status, message } = dataObj;

			if ( status === 'error' ) {
				form.innerHTML = message;
				return;
			}

			if ( 'undefined' !== typeof data.rePurchaseCourse ) {
				Swal.fire({
				  title: data.rePurchaseCourse.titlePopup,
				  // icon: "info",
				  html: data.rePurchaseCourse.html,
				  showCloseButton: true,
				  confirmButtonText: data.rePurchaseCourse.purchaseBtn,
				  preConfirm: async () => {
				  	let repurchaseType = Swal.getPopup().querySelector('input[name="_lp_allow_repurchase_type"]:checked').value;
				  	formData.append( 'repurchaseType', repurchaseType );
				    try {
				      const response = await fetch( lpWoo.url_ajax + lang, { method: 'POST', body: formData } );
				      if ( response.status === 'error' ) {
		      				form.innerHTML = message;
		      				return;
		      			}
				      return response.json();
				    } catch (error) {
				      console.log(error);
				    }
				  },
				}).then((result) => {
				  /* Read more about isConfirmed, isDenied below */
				  if (result.isConfirmed) {
				    // console.log(result.value);
				    processingAfterAddtoCart( result.value.data, btnSubmit, itemID );
				  }
				});
			} else {
				processingAfterAddtoCart( data, btnSubmit, itemID );
			}
		} )
		.catch( ( err ) => console.log( err ) )
		.finally( () => {
			//isAddingToCart.splice( indexOf, 1 );
		} )
};
const processingAfterAddtoCart = (data, btnSubmit, itemID) => {
	if ('undefined' !== typeof data.redirect_to && data.redirect_to !== '') {
	    btnSubmit.innerText = lpWoo.redirect_i18n;
	    window.location = data.redirect_to;
	} else {
	    // Find all form item id same set new HTML
	    const elInput = document.querySelectorAll(`input[name="item-id"][value="${ itemID }"]`);
	    elInput.forEach((el) => {
	        el.closest('form[name=form-add-item-to-cart]').outerHTML = data.button_view_cart;
	    });

	    const el_mini_cart_count = document.querySelectorAll('.minicart_hover .items-number');
	    if (el_mini_cart_count.length) {
	        el_mini_cart_count.forEach((el) => {
	            el.innerHTML = data.count_items;
	        });
	    }

	    // ThimElKit count items cart
	    const el_thim_el_kit_cart_count = document.querySelectorAll('.thim-ekits-mini-cart .cart-items-number');
	    if (el_thim_el_kit_cart_count.length) {
	        el_thim_el_kit_cart_count.forEach((el) => {
	            el.innerHTML = data.count_items;
	        });
	    }

	    // Update cart widget
	    const el_widget_shopping_cart_contents = document.querySelectorAll('.widget_shopping_cart_content');
	    if (el_widget_shopping_cart_contents.length) {
	        el_widget_shopping_cart_contents.forEach((el) => {
	            el.innerHTML = data.widget_shopping_cart_content;
	        });
	    }
	}
};

const check_reload_browser = () => {
	window.addEventListener(
		'pageshow',
		function( event ) {
			const hasCache = event.persisted ||
				( typeof window.performance != 'undefined'
					&& String( window.performance.getEntriesByType( 'navigation' )[ 0 ].type ) === 'back_forward' );

			//console.log( hasCache );

			if ( hasCache ) {
				location.reload();
			}
		}
	);
};

// Fix event browser back - load page to show 'view cart' button if added to cart
check_reload_browser();
